<template>
  <b-card>
    <h4 class="mb-0">
      Detail Kehadiran
    </h4>
    <validation-observer ref="validUpdate">
      <b-form class="mt-1" @submit.prevent="validationForm">
        <b-row>
          <b-col md="6">
            <validation-provider #default="{ errors }" name="NIP" rules="required">
                <b-form-group label="NIP" label-for="employee_nip" :state="errors.length > 0 ? false : null">
                  <v-select id="employee_nip" v-model="dataStudent.employee_nip"
                    :reduce="(employee_nip) => employee_nip.nip" placeholder="Pilih Pegawai"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="listPegawai" label="name" disabled/>
                  <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
              <validation-provider #default="{ errors }" name="employee_presence_type_id" rules="required">
                <b-form-group label="Type Kehadiran" label-for="employee_presence_type_id" :state="errors.length > 0 ? false : null">
                  <v-select id="employee_presence_type_id" v-model="dataStudent.employee_presence_type_id"
                    :reduce="(employee_presence_type_id) => employee_presence_type_id.real_id" placeholder="Pilih Tipe Kehadiran"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="listPresentType" label="presence_type_desc" disabled/>
                  <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            
            <b-form-group label="Tanggal" label-for="date">
              <validation-provider #default="{ errors }" name="Tanggal" rules="required">
                <b-form-input id="date" v-model="dataStudent.date" :state="errors.length > 0 ? false : null"
                  placeholder="Tanggal" type="date" disabled/>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group label="Jam Masuk" label-for="time_in">
              <validation-provider #default="{ errors }" name="Jam Masuk" rules="required">
                <b-form-input id="time_in" v-model="dataStudent.time_in" :state="errors.length > 0 ? false : null"
                  placeholder="Jam Masuk" disabled/>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group label="Jam Keluar" label-for="time_out">
              <validation-provider #default="{ errors }" name="Anak ke" rules="required">
                <b-form-input id="time_out" v-model="dataStudent.time_out" :state="errors.length > 0 ? false : null"
                  placeholder="Jam Keluar" disabled/>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row class="text-right">
          <!-- submit and reset -->
          <b-col>
            <b-button v-ripple.400="'rgba(186, 191, 199, 0.15)'" type="reset" variant="outline-secondary" class="mr-1"
              @click="() => $router.go(-1)">
              Cancel
            </b-button>            
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
    <!-- <b-container> -->

    <!-- </b-container> -->
  </b-card>
</template>

<script>
import vSelect from 'vue-select'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import {
  // BContainer,
  BCard,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  // BFormCheckbox,
  BForm,
  BButton,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import dataCountries from '@/assets/json/countries.json'
import dataProvinces from '@/assets/json/provinces.json'
import dataCities from '@/assets/json/cities.json'
import dataDistrics from '@/assets/json/districts.json'
import dataSubDistrics from '@/assets/json/subdistricts.json'

export default {
  name: 'DataSiswaEdit',
  components: {
    vSelect,
    ValidationProvider,
    ValidationObserver,
    BCard,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    // BContainer,
    // BFormCheckbox,
    BForm,
    BButton,
  },
  directives: {
    Ripple,
  },
  data: () => ({
    listPegawai: [],
    listPresentType: [],
    dataCountries,
    dataProvinces,
    dataCities,
    dataDistrics,
    dataSubDistrics,
    required,
    name: '',
    dataStudent: {
      employee_nip: '',
      employee_presence_type_id: '',
      date: '',
      time_in: '',
      time_out: '',
      _method: 'PUT',
    },
  }),
  computed: {
  },
  created() {
    this.getStudentDetail()
    this.getpegawai()
    this.getpresenttype()
  },
  methods: {
    async getpegawai() {
      try {
        const response = await this.$http.get('/employees')
        this.listPegawai = response.data.data
      } catch (err) {
        this.showToast('danger', 'top-center', 'Get Presences Not Success')
        console.log(err)
      }
    },
    async getpresenttype() {
      try {
        const response = await this.$http.get('/employeepresencetypes')
        this.listPresentType = response.data.data
      } catch (err) {
        this.showToast('danger', 'top-center', 'Get Presences Not Success')
        console.log(err)
      }
    },
    async getStudentDetail() {
      try {
        const studentId = this.$route.params.id
        const response = await this.$http.get(`/employeepresences/${studentId}`)
        this.fillStudentData(response.data.data)
      } catch (err) {
        console.log(err)
      }
    },
    fillStudentData(data) {
      this.dataStudent.employee_nip = data.employee_nip
      this.dataStudent.employee_presence_type_id = data.employee_presence_type_id
      this.dataStudent.time_in = data.time_in
      this.dataStudent.time_out = data.time_out
      this.dataStudent.date = data.date.substr(0, 10)
    },
    validationForm() {
      this.$refs.validUpdate.validate().then(async success => {
        if (success) {
          try {
            const studentId = this.$route.params.id
            const dataFormStudent = new FormData()
            Object.keys(this.dataStudent).forEach(key => {
              dataFormStudent.append(key, this.dataStudent[key])
            })
            await this.$http.post(`/employeepresences/${studentId}`, dataFormStudent, {
              headers: {
                'content-type': 'multipart/form-data',
              },
            })
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Update Data Kehadiran Berhasil',
                icon: 'AlertCircleIcon',
                variant: 'success',
              },
            })
            this.$router.push({ name: 'data-kehadiran' })
            // alertnya()
          } catch (err) {
            console.log(err)
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Update Data Kehadiran Tidak Berhasil',
                icon: 'AlertCircleIcon',
                variant: 'danger',
              },
            })
            console.log(err)
          }
        }
      })
    },
  },
}
</script>

<style lang="scss">
@import "~@core/scss/vue/libs/vue-select.scss";
</style>
