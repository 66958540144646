var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',[_c('h4',{staticClass:"mb-0"},[_vm._v(" Detail Kehadiran ")]),_c('validation-observer',{ref:"validUpdate"},[_c('b-form',{staticClass:"mt-1",on:{"submit":function($event){$event.preventDefault();return _vm.validationForm.apply(null, arguments)}}},[_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('validation-provider',{attrs:{"name":"NIP","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"NIP","label-for":"employee_nip","state":errors.length > 0 ? false : null}},[_c('v-select',{attrs:{"id":"employee_nip","reduce":function (employee_nip) { return employee_nip.nip; },"placeholder":"Pilih Pegawai","dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.listPegawai,"label":"name","disabled":""},model:{value:(_vm.dataStudent.employee_nip),callback:function ($$v) {_vm.$set(_vm.dataStudent, "employee_nip", $$v)},expression:"dataStudent.employee_nip"}}),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false : null}},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}])}),_c('validation-provider',{attrs:{"name":"employee_presence_type_id","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Type Kehadiran","label-for":"employee_presence_type_id","state":errors.length > 0 ? false : null}},[_c('v-select',{attrs:{"id":"employee_presence_type_id","reduce":function (employee_presence_type_id) { return employee_presence_type_id.real_id; },"placeholder":"Pilih Tipe Kehadiran","dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.listPresentType,"label":"presence_type_desc","disabled":""},model:{value:(_vm.dataStudent.employee_presence_type_id),callback:function ($$v) {_vm.$set(_vm.dataStudent, "employee_presence_type_id", $$v)},expression:"dataStudent.employee_presence_type_id"}}),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false : null}},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}])}),_c('b-form-group',{attrs:{"label":"Tanggal","label-for":"date"}},[_c('validation-provider',{attrs:{"name":"Tanggal","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"date","state":errors.length > 0 ? false : null,"placeholder":"Tanggal","type":"date","disabled":""},model:{value:(_vm.dataStudent.date),callback:function ($$v) {_vm.$set(_vm.dataStudent, "date", $$v)},expression:"dataStudent.date"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Jam Masuk","label-for":"time_in"}},[_c('validation-provider',{attrs:{"name":"Jam Masuk","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"time_in","state":errors.length > 0 ? false : null,"placeholder":"Jam Masuk","disabled":""},model:{value:(_vm.dataStudent.time_in),callback:function ($$v) {_vm.$set(_vm.dataStudent, "time_in", $$v)},expression:"dataStudent.time_in"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Jam Keluar","label-for":"time_out"}},[_c('validation-provider',{attrs:{"name":"Anak ke","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"time_out","state":errors.length > 0 ? false : null,"placeholder":"Jam Keluar","disabled":""},model:{value:(_vm.dataStudent.time_out),callback:function ($$v) {_vm.$set(_vm.dataStudent, "time_out", $$v)},expression:"dataStudent.time_out"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('b-row',{staticClass:"text-right"},[_c('b-col',[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(186, 191, 199, 0.15)'),expression:"'rgba(186, 191, 199, 0.15)'",modifiers:{"400":true}}],staticClass:"mr-1",attrs:{"type":"reset","variant":"outline-secondary"},on:{"click":function () { return _vm.$router.go(-1); }}},[_vm._v(" Cancel ")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }